// import PropTypes from 'prop-types'
import { Component } from 'react'
import { LabelType } from '../../types/Label.type';
import { Table, TableRow, TableCell, Typography, Grid, Box, TableBody } from '@mui/material';
import { CalculatorLabelsType } from '../../types/CalculatorLabel.type';
import { User } from '../../types/User.type';
import { toLocalDate } from "../../utils/DateConversion";
import mainLogo from "../../assets/mainLogo-black.png";

interface PrintSummaryProps {
    jobInfo?: any;
    jobDetail?: any & { Input: any };
    valueFormatter: (label: LabelType, result: any) => string;
    channelBlenderLabels?: CalculatorLabelsType;
    userData: User | null
}

const labels: LabelType[] = [
    { id: "Header1", label: "Project Information", header: true, color: '#FDB40880' },
    { id: "name", label: "Job Name" },
    { id: "jobLocation", label: "Job Location" },
    { id: "ahuOem", label: "OEM" },
    { id: "engineeringFirm", label: "Eng. Firm" },
    { id: "salesRep", label: "Sales Rep" },


    { id: "Header2", label: "Inputs", header: true, color: '#E15B2680' },
    { id: "ahuTag", label: "AHU Tag" },
    { id: "Input.SupplyAirFlow", label: "Supply Airflow [CFM]" },
    { id: "Input.MinOAFlow", label: "Min. Outside Airflow [CFM]" },
    { id: "Input.OADesignTemp", label: "OA Design Temp. [°F]" },

    { id: "Header3", label: "Channel Blender", header: true, color: '#3D7FCF80' },
    { id: "ModelID", label: "Model ID" },
    { id: "ChannelBlenderQty", label: "Quantity" },
    { id: "Height", label: "Height (Y)" },
    { id: "Width", label: "Width (X)" },
    { id: "Depth", label: "Depth (Z)" },
    { id: "PressureDrop25pcOA", label: 'Est. Pressure Drop 25% OA' },
    { id: "PressureDrop100pcOA", label: 'Est. Pressure Drop 100% OA' },
    { id: "RequiredActuatorQty", label: "Qty. Actuators Required" },
    { id: "Input.KnockdownConstruction", label: "Knockdown" },
    { id: "Input.SplitChannelBlender", label: "Split Channel Blender" },
    { id: "Input.DedicatedMinOADuct", label: "Dedicated Min. OA Duct" },

    { id: "HeaderOA", label: "Mixing Box (all dimensions inches)", header: true, color: '#3ECF4780' },

    { id: "Input.InsidePlenumWidth", label: "Internal Width" },
    { id: "Input.InsidePlenumHeight", label: "Internal Height" },
    { id: "MixingBoxDepth", label: "Mixing Box Depth" },

    { id: "OAOpeningWidth", label: "OA1 Dimension" },
    { id: "OAOpeningLength", label: "OA2 Dimension" },
    { id: "RAOpeningWidth", label: "RA1 Dimension" },
    { id: "RAOpeningLength", label: "RA2 Dimension" },
    { id: "A", label: "Opening Dimension A" },
    { id: "B", label: "Opening Dimension B" },
    { id: "C", label: "Opening Dimension C" },
];

export class PrintSummary extends Component<PrintSummaryProps> {

    footer_font_size = 10;
    body_font_size = 14;


    render() {
        const { jobDetail, jobInfo, valueFormatter, channelBlenderLabels, userData } = this.props;
        // console.log("jobDetail", jobDetail);
        // console.log("jobInfo", jobInfo);
        // console.log("channelBlenderLabels", channelBlenderLabels);
        const data = { ...jobDetail, ...jobInfo, salesRep: jobInfo.salesRep ?? `${userData?.firstName} ${userData?.lastName}` };
        const renderImg = data.Input?.SplitChannelBlender
            ? channelBlenderLabels?.OrientationCode[data.Input?.OrientationCode].doubleImg
            : channelBlenderLabels?.OrientationCode[data.Input?.OrientationCode].singleImg;
        return (
            // <Box  sx={{maxWidth:'8.5in',padding:'0.5in'}}>
            <Box sx={{ paddingLeft: '0.5in' }} maxHeight={'11in'} className='print-container' >
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <Box display='flex' justifyContent={'center'} className='header-logo' >
                            <img src={mainLogo} alt='Belnder Logo' height={'80px'} />
                        </Box>
                        <Typography fontSize={this.body_font_size} >
                            **Preliminary Design – Not for construction. Final Submittal from Blender Products Inc. required prior to production.
                        </Typography>
                    </Grid>
                    <Grid item xs={5}>
                        <Box
                            sx={{ alignContent: "center", width: "100%", height: '100%' }}
                            flexDirection={{ xs: "column", sm: 'column' }}
                            alignContent={'space-around'}
                            display={"flex"}
                            justifyContent={"space-around"}>
                            <img alt={channelBlenderLabels?.OrientationCode[data.Input.OrientationCode].label}
                                style={{ maxWidth: '100%', maxHeight: "350px", alignSelf: "center" }}
                                src={`${channelBlenderLabels?.OrientationCode[data.Input?.OrientationCode].boxImg}`}
                                loading='lazy'
                            />
                            {renderImg?.toLowerCase().startsWith("http") && (
                                <img alt={channelBlenderLabels?.OrientationCode[data.Input?.OrientationCode].label}
                                    style={{ maxWidth: '100%', maxHeight: "350px", alignSelf: "center" }}
                                    src={`${renderImg}`}
                                    loading='lazy' />
                            )}
                        </Box>
                    </Grid>
                    <Grid item xs={7}>
                        {/* <TableContainer component={Paper}> */}
                        <Table >
                            <TableBody>
                                {labels.map((label) => (
                                    <TableRow key={label.id} sx={{ maxHeight: '10px' }}>


                                        <TableCell colSpan={label.header ? 2 : 1} align='left' padding='none' >
                                            <Typography
                                                key={label.label}
                                                fontFamily={label.header ? "Montserrat Bold" : "Montserrat Regular"}
                                                fontSize={this.body_font_size}
                                                // fontWeight={label.header ? 'bold' : undefined}
                                                sx={{
                                                    backgroundColor: label.color,
                                                    paddingLeft: '4px',
                                                }}>
                                                {label.label}
                                            </Typography>
                                        </TableCell>
                                        {!label.header && <TableCell sx={{ paddingLeft: '4px', border: '1px solid lightGray', width: '50%', wordWrap: 'break-word' }} padding='none'>
                                            <Typography component={"span"} fontFamily={"Montserrat Regular"} fontSize={this.body_font_size}>
                                                {valueFormatter(label, data)}
                                            </Typography>
                                        </TableCell>}
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                        {/* </TableContainer> */}
                    </Grid>
                </Grid>

                <Grid container className='footer' maxWidth={'8.2in'}>
                    <Grid item sm={2} display={'flex'} justifyContent={'start'}>
                        <Typography textAlign={'left'} fontSize={this.footer_font_size}>
                            {toLocalDate(new Date())}
                        </Typography>
                    </Grid>
                    <Grid item sm={8} alignSelf={'center'} display={'flex'} justifyContent={'center'} flexDirection={'column'}>
                        <Typography textAlign={'center'} fontSize={this.footer_font_size}>
                            16600 E. 33rd Drive, Suite 30
                        </Typography>
                        <Typography textAlign={'center'} fontSize={this.footer_font_size}>
                            Aurora, CO 80011
                        </Typography>
                        <Typography textAlign={'center'} fontSize={this.footer_font_size} paddingTop={'0.5em'}>
                            Info@BlenderProducts.com | 800.523.5705
                        </Typography>
                    </Grid>
                    <Grid item sm={2} display={'flex'} justifyContent={'flex-end'} >
                        <Typography textAlign={'right'} fontSize={this.footer_font_size} >
                            {jobDetail.SoftwareVersion}
                        </Typography>
                    </Grid>
                </Grid>
            </Box >
        )
    }
}

export default PrintSummary
