import React, { ChangeEvent, useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ErrorIcon from "@mui/icons-material/Error";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import WarningIcon from "@mui/icons-material/Warning";
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Collapse,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  List,
  ListItem,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";

import { ImageSelector } from "../../components/ImageSelector";
import { CalculateResponseType, CalculatorResultType } from "../../types/CaculateResponse.type";
import { CaculatorValueType } from "../../types/CaculatorValue.type";
import { CalculatorLabelsType } from "../../types/CalculatorLabel.type";
import { JobDetailsType, JobInfoType, JobStateType } from "../../types/JobInfo.type";
import { useAuth } from "../../utils/Authentication";
import { useServerAPI } from "../../utils/Session/ServerAPI";
import { LayoutConfig } from "../../types/LayoutConfig.type";

type FormField = CaculatorValueType;

type FormData = {
  [key: string]: {
    type: string;
    value: any;
  };
};

interface PROPS {
  channelBlenderDefaults?: any;
  channelBlenderLabels?: CalculatorLabelsType;
  jobState: JobStateType | null;
  jobDetails: CalculateResponseType<CalculatorResultType> | null;
  layout?: LayoutConfig;
  onCreateCopy: () => void;
  onPerformCalculation: (
    jobInfo: JobInfoType,
    calculatedResult: CalculatorResultType,
    entityId?: string,
    options?: JobDetailsType[]
  ) => void;
  onFormValueChanged?: (values: FormData) => void;
}

const Calculator = ({ channelBlenderLabels = {}, layout, ...props }: PROPS) => {
  const navigate = useNavigate();
  const session = useAuth();
  const serverApi = useServerAPI();

  const [defaults, setDefaults] = useState<FormField[]>([]);

  const [jobInfoFormdata, setJobInfoFormData] = useState<JobInfoType>({
    name: "",
    type: "CB",
    date: "",
    jobLocation: "",
    ahuTag: "",
    ahuOem: "",
    engineeringFirm: session.userData?.company ?? "",
    isRequestingPartyBasisDesign: undefined,
    salesRep: session.userData?.firstName + " " + session.userData?.lastName
  });
  const [calculatorFormData, setCalculatorFormData] = useState<FormData>({});

  const [formErrors, setFormErrors] = useState<any>({});

  const [calculating, setCalculating] = useState<boolean>(false);

  const [calculationErrors, setCalculationErrors] = useState<string[]>([]);

  const [calculationWarnings, setCalculationWarnings] = useState<string[]>([]);

  const [expanded, setExpanded] = useState(false);

  const advancedSettings = defaults.filter((item) => item.isAdvancedSetting);

  const regularFields = defaults.filter((item) => !item.isAdvancedSetting);

  const { channelBlenderDefaults, jobDetails } = props;

  useEffect(() => {
    if (jobDetails?.result) {
      const { jobInfo, result } = jobDetails;
      // set the jobInfo
      setJobInfoFormData({
        name: jobInfo.name,
        type: jobInfo.type,
        date: jobInfo.date ?? "",
        jobLocation: jobInfo.jobLocation,
        ahuTag: jobInfo.ahuTag,
        ahuOem: jobInfo.ahuOem,
        engineeringFirm: jobInfo.engineeringFirm,
        isRequestingPartyBasisDesign:
          jobInfo.isRequestingPartyBasisDesign != null ? jobInfo.isRequestingPartyBasisDesign : undefined,
        salesRep: jobInfo.salesRep ?? session.userData?.firstName + " " + session.userData?.lastName
      });
      setCalculationErrors(result?.Status === "Error" ? result?.StatusMessages : []);
      setCalculationWarnings(result?.Status === "Warning" ? result?.StatusMessages : []);
    }
    if (channelBlenderDefaults) initializeForm(channelBlenderDefaults, jobDetails?.result?.Input);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [channelBlenderDefaults, jobDetails]);

  useEffect(() => {
    if (props.onFormValueChanged) props.onFormValueChanged(calculatorFormData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calculatorFormData]);

  // const showSplitChannelBlender = React.useMemo(() => channelBlenderLabels['OrientationCode'][calculatorFormData?.OrientationCode.value].horizontal, [calculatorFormData, channelBlenderLabels]);
  // const showBaseRailHeight  = React.useMemo(() => 
  const showField = React.useCallback((fieldId: string) => {
    if (fieldId === "BaseRailHeight") return channelBlenderLabels['OrientationCode'][calculatorFormData?.OrientationCode.value].horizontal;
    if (fieldId === "SplitChannelBlender") return !channelBlenderLabels['OrientationCode'][calculatorFormData?.OrientationCode.value].horizontal;
    return true
  }, [calculatorFormData, channelBlenderLabels]);

  const initializeForm = (formFields: any, calculatedJobInputs?: any) => {
    //calculatedJobInputs used when showing the previously calculated job results
    const fields: FormField[] = [];
    const calculatorFormData = {} as FormData;

    const addField = (key: string) => {
      const field = formFields[key];
      calculatorFormData[key] = {
        //use the calculated job inputs to populate the form when viewwing the calculated job results
        value: (calculatedJobInputs && calculatedJobInputs[key]) ?? field.default,
        type: field.type,
      };
      fields.push({
        id: key,
        type: field.type,
        values: Array.isArray(field.values) ? field.values : [],
        label: field.label,
        minimum: field.minimum,
        maximum: field.maximum,
        tip: field.tip || "",
        isAdvancedSetting: field.isAdvancedSetting || false,
      });
    };
    layout?.promptOrder?.split(",").forEach((key) => addField(key.trim()));
    Object.keys(formFields).forEach((key) => {
      if (!layout?.promptOrder.includes(key)) addField(key);
    });

    setCalculatorFormData(calculatorFormData);
    setDefaults(fields);
  };

  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleAdvancedSettingsToggle = () => {
    setExpanded(!expanded);
  };

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    const { name, value, readOnly, required } = e.target;

    if (readOnly) return;

    setFormErrors({
      ...formErrors,
      [name]: required && value.trim() === "" ? "required" : "",
    });
  };

  const handleInputChange = (id: string, value: any) => {
    setCalculatorFormData({
      ...calculatorFormData,
      [id]: {
        type: calculatorFormData[id].type,
        value: value,
      },
    });
  };

  const handleJobInfoInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, required } = e.target;
    setJobInfoFormData({
      ...jobInfoFormdata,
      [name]: name === "isRequestingPartyBasisDesign" ? (value === "true" ? true : false) : value,
    });

    setFormErrors({
      ...formErrors,
      [name]: required && value.trim() === "" ? "required" : "",
    });
  };

  const handleIntegerInputChange = (
    id: string,
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    field: FormField
  ) => {
    const inputValue = e.target.value.trim();
    let customValidity = "";
    let numericValue: any = null;

    if (isValidInteger(inputValue)) {
      numericValue = parseInt(inputValue, 10);
      customValidity = getCustomValidity(numericValue, field);
    } else {
      if (inputValue.startsWith("-") && inputValue.length === 1) {
        numericValue = inputValue;
      } else {
        numericValue = inputValue === "" ? "" : calculatorFormData[field.id];
      }
      if (numericValue === calculatorFormData[field.id]) {
        customValidity = formErrors[field.id];
      } else {
        customValidity = "Invalid input. Please enter a valid Integer";
      }
    }

    setCalculatorFormData({
      ...calculatorFormData,
      [id]: {
        type: calculatorFormData[id].type,
        value: numericValue,
      },
    });
    setFormErrors({ ...formErrors, [id]: customValidity });
  };

  const handleFloatInputChange = (
    id: string,
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    field: FormField
  ) => {
    const inputValue = e.target.value.trim();
    let customValidity = "";
    let numericValue: any = "";

    if (isValidFloat(inputValue)) {
      numericValue = inputValue;
      if (inputValue.endsWith(".") || inputValue === "-0") {
        customValidity = "Invalid input. Please enter digits after the decimal point.";
      } else {
        customValidity = getCustomValidity(numericValue, field);
      }
    } else {
      if (
        (inputValue.startsWith("-") && inputValue.length === 1) ||
        (inputValue.match(/.*[-.].*/) && inputValue.match(/^[-.\d]+$/))
      ) {
        numericValue = inputValue;
      } else {
        numericValue = inputValue === "" ? "" : calculatorFormData[field.id];
      }
      if (numericValue === calculatorFormData[field.id]) {
        customValidity = formErrors[field.id];
      } else {
        customValidity = "Invalid input. Please enter a valid float";
      }
    }

    if (numericValue !== calculatorFormData[field.id]) {
      setCalculatorFormData({
        ...calculatorFormData,
        [id]: {
          type: calculatorFormData[id].type,
          value: numericValue,
        },
      });
    }
    setFormErrors({ ...formErrors, [id]: customValidity });
  };

  const isValidInteger = (inputValue: any) => /^-?\d+$/.test(inputValue);

  const isValidFloat = (inputValue: any) => /^-?\d+(\.\d*)?$/.test(inputValue);

  const getCustomValidity = (value: any, field: FormField) => {
    if (
      (field.type === "integer" || field.type === "float") &&
      ((field.minimum !== undefined && value < field.minimum) || (field.maximum !== undefined && value > field.maximum))
    ) {
      return `Value is out of range. Should be between ${field.minimum} and ${field.maximum}`;
    }
    return "";
  };

  const handleGoBack = () => {
    navigate("/"); // Go back to the previous screen
  };

  const prepareCalculatorFormData = (calculatorFormData: FormData) => {
    const formattedData = {} as any;

    Object.keys(calculatorFormData).forEach((key) => {
      const { type, value } = calculatorFormData[key];
      formattedData[key] = type === "integer" ? parseInt(value) : type === "float" ? parseFloat(value) : value;
    });

    return formattedData;
  };

  const renderFiled = (field: FormField): React.ReactNode => {
    let content: React.ReactNode = null;
    switch (field.type) {
      case "float":
      case "integer":
        content = (
          <TextField
            id={field.id}
            name={field.id}
            value={calculatorFormData[field.id].value}
            type={"text"}
            fullWidth
            onChange={(e) => {
              field.type === "float"
                ? handleFloatInputChange(field.id, e, field)
                : handleIntegerInputChange(field.id, e, field);
            }}
            variant='outlined'
            error={formErrors[field.id] ? true : false}
            helperText={formErrors[field.id]}
            InputProps={{
              readOnly: isReadOnly,
            }}
          />
        );
        break;
      case "boolean":
        content = (
          <Switch
            checked={calculatorFormData[field.id].value}
            onChange={(e) => handleInputChange(field.id, e.target.checked)}
            disabled={isReadOnly}
          />
        );
        break;

      case "enum":
        // if there is a labels override define use the ImageSelector instead
        content = channelBlenderLabels[field.id] ? (
          <ImageSelector
            field={field}
            label={channelBlenderLabels[field.id]}
            formErrors={formErrors}
            isReadOnly={isReadOnly}
            calculatorFormData={calculatorFormData}
            onChange={handleInputChange}
          />
        ) : (
          <Select
            name={field.id}
            value={calculatorFormData[field.id].value}
            required
            fullWidth
            onChange={(e) => handleInputChange(field.id, e.target.value)}
            error={formErrors[field.id] ? true : false}
            disabled={isReadOnly}>
            {field.values.map((value) => (
              <MenuItem value={value} key={value}>
                {value}
              </MenuItem>
            ))}
          </Select>
        );
        break;
      default:
        // control not implemented
        break;
    }

    return content;
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    if (session.userData?.isOEM && jobInfoFormdata.isRequestingPartyBasisDesign == null) {
      setFormErrors({ ...formErrors, isRequestingPartyBasisDesign: "required" });
      return;
    }

    setCalculating(true);
    const jobInfo: JobInfoType = { ...jobInfoFormdata, date: new Date().toISOString() };
    try {
      const response = await serverApi.calculateChannelBlender(prepareCalculatorFormData(calculatorFormData), jobInfo);
      props.onPerformCalculation(response.jobInfo, response.result, response.entityId, response.options);
      setCalculationErrors(response.result?.Status === "Error" ? response.result?.StatusMessages : []);
      setCalculationWarnings(response.result?.Status === "Warning" ? response.result?.StatusMessages : []);
      setCalculating(false);
      handleScrollToTop();
    } catch (e: any) {
      toast.error(e?.message);
      setCalculating(false);
    }
  };

  const isReadOnly = props.jobState === "Accepted";

  return (
    <Grid container>
      <Grid item display={"flex"} alignItems={"center"} sx={{ cursor: "pointer" }} onClick={handleGoBack}>
        <IconButton edge='start' color='inherit' aria-label='back' disableRipple>
          <ChevronLeftIcon color='primary' fontSize={"large"} />
        </IconButton>
        <Typography variant='h5' color={"primary"}>
          Channel Blender Job
        </Typography>
      </Grid>
      {calculating && (
        <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={true}>
          <CircularProgress color='primary' />
        </Backdrop>
      )}
      {Array.isArray(defaults) && defaults.length > 0 && (
        <Paper sx={{ padding: "24px", gap: 20 }}>
          {calculationErrors.length > 0 && (
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <ErrorIcon color='error' style={{ marginRight: "8px" }} />
                <Typography variant='h5' color='error' fontSize={"16px"}>
                  Error Messages
                </Typography>
              </Box>

              <List sx={{ paddingLeft: "10px" }}>
                {calculationErrors.map((error, index) => (
                  <ListItem key={`error - ${index + 1}`} disablePadding sx={{ display: "flex", alignItems: "baseline" }}>
                    <Typography fontSize={"14px"} minWidth={"15px"}>{`${index + 1}. `}</Typography>
                    <Typography fontSize={"14px"}>{error}</Typography>
                  </ListItem>
                ))}
              </List>
            </Box>
          )}
          {calculationWarnings.length > 0 && (
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <WarningIcon color='warning' fontSize={"small"} style={{ marginRight: "8px" }} />
                <Typography variant='h5' color='warning.main' fontSize={"16px"}>
                  Warning Messages
                </Typography>
              </Box>

              <List sx={{ paddingLeft: "10px" }}>
                {calculationWarnings.map((warning, index) => (
                  <ListItem
                    key={`warning - ${index + 1}`}
                    disablePadding
                    sx={{ display: "flex", alignItems: "baseline" }}>
                    <Typography fontSize={"14px"} minWidth={"15px"}>{`${index + 1}. `}</Typography>
                    <Typography fontSize={"14px"}>{warning}</Typography>
                  </ListItem>
                ))}
              </List>
            </Box>
          )}
          <Grid container gap={3}>
            <Grid container gap={4}>
              <form onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                  {/* Job Details */}
                  <Grid item xs={6} key={"name"}>
                    <InputLabel
                      htmlFor={"name"}
                      sx={{
                        fontFamily: "Montserrat Bold",
                        fontSize: "12px",
                        margin: "5px",
                      }}>
                      Job Name
                    </InputLabel>
                    <TextField
                      id={"name"}
                      name={"name"}
                      type={"text"}
                      value={jobInfoFormdata.name}
                      fullWidth
                      required
                      onBlur={handleBlur}
                      onChange={handleJobInfoInputChange}
                      variant='outlined'
                      error={formErrors.name ? true : false}
                      helperText={formErrors.name}
                      InputProps={{
                        readOnly: isReadOnly,
                      }}
                    />
                  </Grid>
                  <Grid item xs={6} key={"ahuTag"}>
                    <InputLabel
                      htmlFor={"ahuTag"}
                      sx={{
                        fontFamily: "Montserrat Bold",
                        fontSize: "12px",
                        margin: "5px",
                      }}>
                      AHU Tag
                    </InputLabel>
                    <TextField
                      id={"ahuTag"}
                      name={"ahuTag"}
                      type={"text"}
                      value={jobInfoFormdata.ahuTag}
                      fullWidth
                      required
                      onBlur={handleBlur}
                      onChange={handleJobInfoInputChange}
                      variant='outlined'
                      error={formErrors.ahuTag ? true : false}
                      helperText={formErrors.ahuTag}
                      InputProps={{
                        readOnly: isReadOnly,
                      }}
                    />
                  </Grid>
                  <Grid item xs={6} key={"jobLocation"}>
                    <InputLabel
                      htmlFor={"jobLocation"}
                      sx={{
                        fontFamily: "Montserrat Bold",
                        fontSize: "12px",
                        margin: "5px",
                      }}>
                      Job Location
                    </InputLabel>
                    <TextField
                      id={"jobLocation"}
                      name={"jobLocation"}
                      type={"text"}
                      value={jobInfoFormdata.jobLocation}
                      fullWidth
                      required
                      onBlur={handleBlur}
                      onChange={handleJobInfoInputChange}
                      variant='outlined'
                      error={formErrors.jobLocation ? true : false}
                      helperText={formErrors.jobLocation}
                      InputProps={{
                        readOnly: isReadOnly,
                      }}
                    />
                  </Grid>
                  <Grid item xs={6} key={"ahuOem"}>
                    <InputLabel
                      htmlFor={"ahuOem"}
                      sx={{
                        fontFamily: "Montserrat Bold",
                        fontSize: "12px",
                        margin: "5px",
                      }}>
                      AHU OEM
                    </InputLabel>
                    <TextField
                      id={"ahuOem"}
                      name={"ahuOem"}
                      type={"text"}
                      value={jobInfoFormdata.ahuOem}
                      fullWidth
                      onBlur={handleBlur}
                      onChange={handleJobInfoInputChange}
                      variant='outlined'
                      error={formErrors.ahuOem ? true : false}
                      helperText={formErrors.ahuOem}
                      InputProps={{
                        readOnly: isReadOnly,
                      }}
                    />
                  </Grid>
                  <Grid item xs={6} key={"engineeringFirm"}>
                    <InputLabel
                      htmlFor={"engineeringFirm"}
                      sx={{
                        fontFamily: "Montserrat Bold",
                        fontSize: "12px",
                        margin: "5px",
                      }}>
                      Specifying Engineering Firm
                    </InputLabel>
                    <TextField
                      id={"engineeringFirm"}
                      name={"engineeringFirm"}
                      type={"text"}
                      value={jobInfoFormdata.engineeringFirm}
                      fullWidth
                      onBlur={handleBlur}
                      onChange={handleJobInfoInputChange}
                      required
                      variant='outlined'
                      error={formErrors.engineeringFirm ? true : false}
                      helperText={formErrors.engineeringFirm}
                      InputProps={{
                        readOnly: isReadOnly,
                      }}
                    />
                  </Grid>
                  <Grid item xs={6} key={"salesRep"}>
                    <InputLabel
                      htmlFor={"salesRep"}
                      sx={{
                        fontFamily: "Montserrat Bold",
                        fontSize: "12px",
                        margin: "5px",
                      }}>
                      Sales Rep
                    </InputLabel>
                    <TextField
                      id={"salesRep"}
                      name={"salesRep"}
                      type={"text"}
                      value={jobInfoFormdata.salesRep}
                      // value={session.userData?.firstName + " " + session.userData?.lastName}
                      fullWidth
                      onBlur={handleBlur}
                      onChange={handleJobInfoInputChange}
                      required
                      error={formErrors.salesRep ? true : false}
                      helperText={formErrors.salesRep}
                      variant='outlined'
                      InputProps={{
                        readOnly: isReadOnly,
                      }}
                    />
                  </Grid>
                  {session.userData?.isOEM && (
                    <Grid item xs={12} key={"isRequestingPartyBasisDesign"}>
                      <InputLabel
                        htmlFor={"isRequestingPartyBasisDesign"}
                        sx={{
                          fontFamily: "Montserrat Bold",
                          fontSize: "12px",
                          margin: "5px",
                        }}>
                        Is Requesting Party Basis Design?
                      </InputLabel>
                      <RadioGroup
                        aria-label='isRequestingPartyBasisDesign'
                        name='isRequestingPartyBasisDesign'
                        value={
                          jobInfoFormdata?.isRequestingPartyBasisDesign != null
                            ? String(jobInfoFormdata?.isRequestingPartyBasisDesign)
                            : ""
                        }
                        onChange={handleJobInfoInputChange}
                        onBlur={handleBlur}
                        row
                        sx={{
                          border: formErrors.isRequestingPartyBasisDesign ? "1px solid red" : "none",
                          borderRadius: "4px",
                          padding: "0px 5px",
                        }}>
                        <FormControlLabel
                          value={String(true)}
                          control={<Radio size='small' />}
                          label='Yes'
                          disabled={isReadOnly}
                        />
                        <FormControlLabel
                          value={String(false)}
                          control={<Radio size='small' />}
                          label='No'
                          disabled={isReadOnly}
                        />
                      </RadioGroup>
                      {formErrors.isRequestingPartyBasisDesign && (
                        <Typography color={"error"} sx={{ marginTop: "4px", alignSelf: "center", fontSize: "12px" }}>
                          {formErrors.isRequestingPartyBasisDesign}
                        </Typography>
                      )}
                    </Grid>
                  )}
                  <Grid
                    item
                    xs={12}
                    key={"blankSpace"}
                    sx={{ borderBottom: "1px solid lightGray" }}
                    marginLeft={"16px"}></Grid>
                  {regularFields.map((field, index) => {
                    var separator = layout?.lineBefore?.includes(field.id) ? (
                      <React.Fragment key={`separator-${index}`}>
                        <Grid item xs={12} sx={{ borderBottom: "1px solid lightGray" }} marginLeft={"16px"}></Grid>
                      </React.Fragment>
                    ) : null;

                    return (
                      <React.Fragment key={field.id}>
                        {separator}
                        {layout?.centerPrompt?.includes(field.id) && <Grid item xs={2}></Grid>}
                        <Grid item xs={layout?.centerPrompt?.includes(field.id) ? 8 : 6} >
                          {showField(field.id) && <>
                            <Tooltip
                              title={field.tip ? <Typography fontSize={"12px"}>{field.tip}</Typography> : ""}
                              placement='top-start'
                              arrow>
                              <InputLabel
                                htmlFor={field.id}
                                sx={{
                                  fontFamily: "Montserrat Bold",
                                  fontSize: "12px",
                                  margin: "5px",
                                }}>
                                {field.label}
                              </InputLabel>
                            </Tooltip>
                            {renderFiled(field)}
                            {(index + 1) % 2 === 0 && <Grid item xs={12} key={`break-${index}`} />}
                          </>}
                        </Grid>
                      </React.Fragment>
                    );
                  })}
                  <Grid item xs={12} sx={{ borderBottom: "1px solid lightGray" }} marginLeft={"16px"}></Grid>
                  <Grid item xs={12} paddingTop={"0 !important"}>
                    <Box
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        cursor: "pointer",
                        paddingTop: "16px",
                        paddingBottom: expanded ? "16px" : 0,
                      }}
                      onClick={handleAdvancedSettingsToggle}>
                      <Typography fontWeight={"bold"}>Advanced Settings</Typography>
                      <IconButton
                        aria-expanded={expanded}
                        aria-label='expand'
                        disableFocusRipple
                        disableRipple
                        sx={{ padding: 0 }}>
                        {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                      </IconButton>
                    </Box>
                  </Grid>
                  <Collapse in={expanded}>
                    <Grid container spacing={2} padding={"0px 0px 12px 16px"}>
                      {advancedSettings.map((field, index) => {
                        return (
                          <React.Fragment key={field.id}>
                            <Grid item xs={6}>
                              <InputLabel
                                htmlFor={field.id}
                                sx={{
                                  fontFamily: "Montserrat Bold",
                                  fontSize: "12px",
                                  margin: "5px",
                                }}>
                                {field.label}
                              </InputLabel>
                              {renderFiled(field)}
                              {(index + 1) % 2 === 0 && <Grid item xs={12} key={`break-${index}`} />}
                            </Grid>
                          </React.Fragment>
                        );
                      })}
                    </Grid>
                  </Collapse>
                </Grid>
                {!isReadOnly && (
                  <>
                    <Grid item xs={12} sx={{ borderBottom: "1px solid lightGray" }} margin={"12px 0px 16px 0px"}></Grid>
                    <Grid item xs={12}>
                      <Button
                        type='submit'
                        variant='contained'
                        color='primary'
                        fullWidth
                        disabled={Object.values(formErrors).some((error) => !!error)}>
                        Calculate Results
                      </Button>
                    </Grid>
                  </>
                )}
                {isReadOnly && (
                  <>
                    <Grid item xs={12} sx={{ borderBottom: "1px solid lightGray" }} margin={"12px 0px 16px 0px"}></Grid>
                    <Grid item xs={12}>
                      <Button variant='contained' color='primary' fullWidth onClick={props.onCreateCopy}>
                        Create Copy
                      </Button>
                    </Grid>
                  </>
                )}
              </form>
            </Grid>
          </Grid>
        </Paper>
      )}
    </Grid>
  );
};

export default Calculator;
